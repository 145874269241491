import {
  isObject,
  isArray,
  mapKeys,
  mapValues,
  snakeCase,
  camelCase,
} from 'lodash'

const mapper = (value: string, transformCase: Function) => {
  return isObject(value) ? toAnyCase(value, transformCase) : value
}

const toAnyCase = (data: any, transformCase: Function) => {
  const _mapValues = (value: string) => mapper(value, transformCase)
  if (isArray(data)) {
    return data.map(mapValues)
  } else if (isObject(data) || data === null) {
    return mapKeys(mapValues(data, _mapValues), (_value: string, key: string) =>
      transformCase(key)
    )
  } else {
    return data
  }
}

const toSnakeCase = (data: any) => {
  return toAnyCase(data, snakeCase)
}

const toCamelCase = (data: any) => {
  return toAnyCase(data, camelCase)
}

export default {
  toSnakeCase,
  toCamelCase,
}

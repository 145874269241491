import { useState } from 'react'
import consigneeApi from '@/services/api/consigneeApi'
import handleResponse from '@/utils/handleResponse'
import { ApiResponse } from 'apisauce'
import { isEmpty } from 'lodash'
import {
  IAppendOrderInstructionRequest,
  IChangeOrderAddressRequest,
  IGetOrderDetailsResponse,
} from '@/types/hooks/order'
import { IResponse } from '@/types/commons/api'

const useOrder = () => {
  const [isOrderLoading, setIsOrderLoading] = useState<boolean>(true)
  const [isSubmittingInstruction, setIsSubmittingInstruction] =
    useState<boolean>(false)
  const [order, setOrder] = useState<IGetOrderDetailsResponse>()
  const [updateInstructionResponse, setUpdateInstructionResponse] =
    useState<IResponse>()

  const getConsigneeOrder = async (token: string): Promise<void> => {
    const res: ApiResponse<any> = await consigneeApi.getOrder(token)
    handleResponse(
      res,
      () => {
        const tempData: IGetOrderDetailsResponse = res?.data?.data || {}
        if (!isEmpty(tempData)) {
          setOrder(tempData)
          setIsOrderLoading(false)
        }
      },
      () => {
        setIsOrderLoading(false)
      }
    )
  }

  const appendOrderInstruction = async (
    data: IAppendOrderInstructionRequest,
    token: string
  ): Promise<void> => {
    setIsSubmittingInstruction(true)
    const res: ApiResponse<any> = await consigneeApi.appendInstruction(
      data,
      token
    )
    handleResponse(
      res,
      () => {
        const tempData: IResponse = res?.data || {}
        if (!isEmpty(tempData)) {
          setUpdateInstructionResponse(tempData)
          setIsSubmittingInstruction(false)
        }
      },
      () => {
        setIsSubmittingInstruction(false)
      }
    )
  }

  const changeOrderAddress = async (
    data: IChangeOrderAddressRequest,
    token: string
  ): Promise<void> => {
    setIsSubmittingInstruction(true)
    const res: ApiResponse<any> = await consigneeApi.changeOrderAddress(
      data,
      token
    )
    handleResponse(
      res,
      () => {
        const tempData: IResponse = res?.data || {}
        if (!isEmpty(tempData)) {
          setUpdateInstructionResponse(tempData)
          setIsSubmittingInstruction(false)
        }
      },
      () => {
        setIsSubmittingInstruction(false)
      }
    )
  }

  return {
    order,
    updateInstructionResponse,
    getConsigneeOrder,
    appendOrderInstruction,
    changeOrderAddress,
    isOrderLoading,
    isSubmittingInstruction,
  }
}

export default useOrder

import React, { useState, useEffect } from 'react'
import { Divider, Row, Space, Spin } from 'antd'
import {
  StyledLayout,
  StyledHeader,
  StyledContent,
  DeliveryContainer,
  DeliveryTitle,
  SubTitle,
  DeliveryData,
  DeliveryOptionsContainer,
  DeliveryOptionsTitle,
  DeliverySection,
  TextEmphasis,
} from './styles'
import { DeliveryOptions, DeliveryOptionsModal } from '@/components/Delivery'
import { DELIVERY_OPTIONS } from '@/utils/constants/deliveryOptions'
import useOrder from '@/hooks/api/useOrder'
import { isEmpty } from 'lodash'
import { useRouter } from 'next/router'
import Status from '@/components/Status'
import { STATUS_LABELS } from '@/utils/constants/status'
import { STATUSES } from '@/utils/constants/enums'
import Success from '@/components/Success'
import { IAddress } from '@/types/commons/constants'
import { mixpanelTrackUserDetails } from '@/components/Mixpanel/helpers'

const Delivery = () => {
  const router = useRouter()
  const {
    order,
    updateInstructionResponse,
    getConsigneeOrder,
    appendOrderInstruction,
    changeOrderAddress,
    isOrderLoading,
    isSubmittingInstruction,
  } = useOrder()

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [modalTitle, setModalTitle] = useState<string>('')
  const [hasSubmittedInstruction, setHasSubmittedInstruction] =
    useState<boolean>(false)
  const [deliveryType, setDeliveryType] = useState<string>('')
  const [successAddressInput, setSuccessAddressInput] = useState<IAddress>()

  useEffect(() => {
    const { t } = router.query
    if (t) {
      getConsigneeOrder(t as string)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query])

  useEffect(() => {
    if (!isEmpty(order)) {
      mixpanelTrackUserDetails(order!.userInfo.externalRef, order!.event)
    }
  }, [order])

  return (
    <>
      {!hasSubmittedInstruction ? (
        <Spin spinning={isOrderLoading} style={{ minHeight: '100vh' }}>
          {!isEmpty(order) && (
            <StyledLayout>
              <StyledHeader>
                <img src='ninja-logo.png' alt='ninjachatservices' />
              </StyledHeader>
              <StyledContent>
                <>
                  <DeliveryContainer>
                    <DeliveryTitle>
                      Your parcel from {order?.shipperName} has been picked up
                    </DeliveryTitle>
                    <Space direction='vertical' size={24}>
                      <div>
                        <SubTitle>Estimated Delivery</SubTitle>
                        <DeliveryData>
                          {order?.expectedDeliveryTime}
                        </DeliveryData>
                      </div>
                      <div>
                        <SubTitle>Delivering to</SubTitle>
                        <DeliveryData>(S){order?.toPostcode}</DeliveryData>
                      </div>
                      <div>
                        <SubTitle>Order Tracking ID</SubTitle>
                        <DeliveryData>{order?.trackingId}</DeliveryData>
                      </div>
                    </Space>
                  </DeliveryContainer>
                  <DeliveryOptionsContainer>
                    <>
                      <DeliveryOptionsTitle>
                        Delivery Options
                      </DeliveryOptionsTitle>
                      <DeliverySection>
                        <TextEmphasis>
                          We will try our best to fulfil your selected delivery
                          option{' '}
                        </TextEmphasis>
                        and seek your understanding if the option is
                        unfulfilled. Optimisation is underway for us to serve
                        you better.
                      </DeliverySection>
                      <Divider />
                      <Row>
                        {DELIVERY_OPTIONS.map((option, index) => (
                          <DeliveryOptions
                            key={index}
                            option={option.option}
                            image={option.image}
                            eligibleReason={
                              order!.appendInstructionEligibilityReason
                            }
                            changeAddressReason={
                              order!.changeAddressEligibilityReason
                            }
                            granularStatus={order!.granularStatus}
                            trackingId={order!.trackingId}
                            setIsModalVisible={setIsModalVisible}
                            setModalTitle={setModalTitle}
                            setDeliveryType={setDeliveryType}
                          />
                        ))}
                      </Row>
                    </>
                  </DeliveryOptionsContainer>
                </>
              </StyledContent>
            </StyledLayout>
          )}
          {!isOrderLoading && isEmpty(order) && (
            <Status type={STATUS_LABELS[STATUSES.INVALID]} />
          )}
        </Spin>
      ) : (
        order && (
          <Success
            trackingId={order.trackingId}
            deliveryType={deliveryType}
            successAddress={successAddressInput}
          />
        )
      )}
      <DeliveryOptionsModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        title={modalTitle}
        isSubmitting={isSubmittingInstruction}
        responseStatus={updateInstructionResponse}
        appendOrderInstruction={appendOrderInstruction}
        changeOrderAddress={changeOrderAddress}
        setHasSubmittedInstruction={setHasSubmittedInstruction}
        setSuccessAddressInput={setSuccessAddressInput}
      />
    </>
  )
}

export default Delivery

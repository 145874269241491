import styled from 'styled-components'
import { Row } from 'antd'

export const DeliveryImg = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
`

export const OptionText = styled.div`
  font-size: 1rem;
`

export const StyledRow = styled(Row)`
  && {
    cursor: pointer;
  }
`

import _get from 'lodash/get'
import { notification } from 'antd'
import { ApiResponse } from 'apisauce'
import { NO_OP } from '@/utils/constants/defaults'

const openErrorNotif = (err: string) => {
  notification['error']({
    message: 'Error',
    description: err,
  })
}

const handleResponse = (
  res: ApiResponse<any, any>,
  successCB = NO_OP,
  errorCB: Function = openErrorNotif
) => {
  if (_get(res, 'data.error.message')) {
    errorCB(_get(res, 'data.error.message'))
  } else {
    successCB()
  }
}

export default handleResponse

import { COLLECTION_METHODS } from './enums'

export const COLLECTION_METHOD = {
  [COLLECTION_METHODS.PICKUP]: {
    type: 1,
    title: 'Pick up',
    description: 'Let us pick up your parcel right from your doorstep.',
    icon: '/pick-up.svg',
  },
  [COLLECTION_METHODS.DROPOFF]: {
    type: 2,
    title: 'Drop off',
    description: 'Drop off your parcel at any Ninja Point island-wide.',
    icon: '/drop-off.svg',
  },
}

import styled, { css } from 'styled-components'
import { Col, Button, Drawer, Modal, Checkbox } from 'antd'
import colors from '@/utils/constants/colors'
import { BREAKPOINTS } from '@/utils/constants/enums'

export const StyledCol = styled(Col)`
  && {
    width: 100%;
  }
`

export const SubmitButton = styled(Button)`
  && {
    font-weight: 600;
    height: 40px;
    background-color: ${colors.BACKGROUND.NINJA_RED};
    border-radius: 4px;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    border: none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  }
`

export const InputHeader = styled.div`
  font-weight: bold;
`

export const NextButton = styled.button<{ disabled?: boolean }>`
  all: unset;
  text-align: center;
  border-radius: 25px;
  height: 40px;
  width: 100%;
  font-size: 0.875rem;
  color: ${colors.TEXT.WHITE};
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  background-color: ${({ disabled }) =>
    !disabled
      ? colors.BACKGROUND.BUTTON_DARK_GREY
      : colors.BACKGROUND.BUTTON_LIGHT_GREY};

  @media (max-width: ${BREAKPOINTS.MD}) {
    height: 50px;
    font-size: 1rem;
  }
`

export const LightButton = styled(NextButton)`
  && {
    background-color: ${colors.BACKGROUND.WHITE};
    border: 1px solid ${colors.TEXT.DARK_GREY};
    color: ${colors.TEXT.DARK_GREY};
  }
`

export const StyledDrawer = styled(Drawer)`
  && {
    .ant-drawer-header {
      text-align: center;
      border-bottom: none;
      padding-bottom: 0.5rem;
    }

    .ant-drawer-title {
      font-weight: bold;
      font-size: 1.125rem;

      @media (max-width: ${BREAKPOINTS.MD}) {
        font-size: 1rem;
      }
    }
  }
`

export const DarkGreyText = styled.span`
  color: ${colors.TEXT.DARK_GREY};
`

export const ReturnText = styled(DarkGreyText)`
  && {
    opacity: 0.7;
  }
`

export const NinjaPointText = styled.a`
  color: ${colors.TEXT.DARK_RED_SELECTED};
  font-weight: 600;
  transition: color 0.3;

  :hover {
    color: ${colors.TEXT.DARK_RED_UNSELECTED};
  }
`

export const StyledModal = styled(Modal)`
  && {
    @media (max-width: ${BREAKPOINTS.MD}) {
      &.ant-modal {
        margin: 0;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        max-width: 100%;
      }

      .ant-modal-content {
        height: 100vh;
      }
    }

    .ant-modal-content {
      overflow: hidden;
      border-radius: 4px;
    }

    .ant-modal-title {
      font-weight: bold;
    }

    .ant-modal-header {
      padding-bottom: 0;
      border-bottom: none;
    }

    .ant-modal-body {
      padding-bottom: 0;
    }

    .ant-modal-footer {
      border-top: none;
    }
  }
`

export const LinkText = styled.span`
  color: ${colors.BACKGROUND.NINJA_RED};
  font-weight: 600;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  && {
    margin: 0.25rem;
    z-index: 10;

    .ant-checkbox .ant-checkbox-inner {
      transform: scale(1.5);
      border: 1px solid rgba(179, 179, 179, 0.7);
      border-radius: 5px;
    }

    .ant-checkbox-checked::after {
      border: none;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${colors.BACKGROUND.NINJA_RED};
      border-color: ${colors.BACKGROUND.NINJA_RED};
    }

    :hover {
      .ant-checkbox .ant-checkbox-checked .ant-checkbox-inner {
        border-color: ${colors.BACKGROUND.NINJA_RED};
      }
    }
  }
`

export const EWalletButtonWrapper = styled.div<{ $disabled: boolean }>`
  ${({ $disabled }) =>
    $disabled
      ? css`
          pointer-events: none;
          cursor: not-allowed;
          filter: opacity(0.4);
        `
      : ''}
`

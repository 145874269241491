import React from 'react'
import { Form } from 'antd'
import { SubmitButton } from '@/utils/styles'
import { IAppendOrderInstructionRequest } from '@/types/hooks/order'
import {
  DELIVERY_INSTRUCTIONS,
  ORDER_INSTRUCTION_TYPE,
} from '@/utils/constants/enums'
import { mixpanelTrackSubmit } from '@/components/Mixpanel/helpers'

type PhoneProps = {
  token: string
  isSubmitting: boolean
  appendOrderInstruction: (
    data: IAppendOrderInstructionRequest,
    token: string
  ) => void
}

const Phone = ({ token, isSubmitting, appendOrderInstruction }: PhoneProps) => {
  const [form] = Form.useForm()

  const handleAppendInstruction = async () => {
    try {
      const dataToPost = {
        type: ORDER_INSTRUCTION_TYPE.PHONE,
        instruction: DELIVERY_INSTRUCTIONS.PHONE,
      }

      mixpanelTrackSubmit(ORDER_INSTRUCTION_TYPE.PHONE)
      appendOrderInstruction(dataToPost, token)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Form name='callBeforeDelivery' form={form} layout='vertical'>
      <div>Give you a call before making the delivery.</div>
      <SubmitButton
        style={{ marginTop: '1.5rem' }}
        key='submit'
        type='primary'
        loading={isSubmitting}
        onClick={handleAppendInstruction}
        block
      >
        Confirm
      </SubmitButton>
    </Form>
  )
}

export default Phone

import React from 'react'
import { Form } from 'antd'
import { SubmitButton } from '@/utils/styles'
import { IAppendOrderInstructionRequest } from '@/types/hooks/order'
import {
  DELIVERY_INSTRUCTIONS,
  ORDER_INSTRUCTION_TYPE,
} from '@/utils/constants/enums'
import { mixpanelTrackSubmit } from '@/components/Mixpanel/helpers'

type DoorbellProps = {
  token: string
  isSubmitting: boolean
  appendOrderInstruction: (
    data: IAppendOrderInstructionRequest,
    token: string
  ) => void
}

const Doorbell = ({
  token,
  isSubmitting,
  appendOrderInstruction,
}: DoorbellProps) => {
  const [form] = Form.useForm()

  const handleAppendInstruction = async () => {
    try {
      const dataToPost = {
        type: ORDER_INSTRUCTION_TYPE.DOORBELL,
        instruction: DELIVERY_INSTRUCTIONS.DOORBELL,
      }

      mixpanelTrackSubmit(ORDER_INSTRUCTION_TYPE.DOORBELL)
      appendOrderInstruction(dataToPost, token)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Form name='callBeforeDelivery' form={form} layout='vertical'>
      <div>Ring your doorbell upon reaching your doorstep.</div>
      <SubmitButton
        style={{ marginTop: '1.5rem' }}
        key='submit'
        type='primary'
        loading={isSubmitting}
        onClick={handleAppendInstruction}
        block
      >
        Confirm
      </SubmitButton>
    </Form>
  )
}

export default Doorbell

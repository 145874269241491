import React from 'react'
import { Container, Title, SubTitle, InputAddress } from './styles'
import { IAddress, IStatus } from '@/types/commons/constants'
import { isEmpty } from 'lodash'
import { FormattedMessage } from 'react-intl'

type StatusProps = {
  type: IStatus
  parameter?: string
  successAddress?: IAddress
  from?: string
}

const Status = ({ type, parameter, successAddress, from }: StatusProps) => {
  const hasSubtitle = type.subtitle !== '' && type.subtitle !== undefined

  return (
    <Container>
      <img src={type.logo} alt='Stop sign' />
      <Title>
        <FormattedMessage id={type.title} />
      </Title>
      {!isEmpty(successAddress) ? (
        // TODO: Use FormattedMessage for the CWP code here in the future
        <>
          <SubTitle>{type.subtitle}</SubTitle>
          <InputAddress>
            {successAddress?.address1} {successAddress?.address2} (S)
            {successAddress?.postcode}
          </InputAddress>
          <SubTitle>{type.subtitle2}</SubTitle>
        </>
      ) : from === 'ba' && hasSubtitle ? (
        <SubTitle>
          <FormattedMessage
            id={type.subtitle}
            values={{
              b: (chunks) => (
                <strong style={{ color: '#4c4c4c' }}>{chunks}</strong>
              ),
              a: (chunks) => (
                <a
                  href='https://www.ninjaxpress.co/en-id/support/contact-us'
                  style={{ color: '#c31424' }}
                >
                  <strong>{chunks}</strong>
                </a>
              ),
            }}
          />
        </SubTitle>
      ) : (
        // TODO: Use FormattedMessage for the CWP code here in the future
        <SubTitle>
          {type.subtitle} {parameter?.toLowerCase()}
        </SubTitle>
      )}
    </Container>
  )
}

export default Status

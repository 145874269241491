import styled from 'styled-components'
import { BREAKPOINTS } from '@/utils/constants/enums'

export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 1.25rem;
  gap: 8px;
`

export const Title = styled.div`
  font-size: 2rem;

  @media (max-width: ${BREAKPOINTS.MD}) {
    font-size: 1.25rem;
  }
`

export const SubTitle = styled.div`
  font-size: 1rem;
  max-width: 500px;
  white-space: pre-line;

  @media (max-width: ${BREAKPOINTS.MD}) {
    font-size: 0.875rem;
  }
`

export const InputAddress = styled(SubTitle)`
  && {
    font-weight: bold;
  }
`

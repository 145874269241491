import styled from 'styled-components'
import { Button } from 'antd'
import colors from '@/utils/constants/colors'
import { BREAKPOINTS } from '@/utils/constants/enums'

type StyledButtonProps = {
  $isSuccess: boolean
}

export const StyledButton = styled(Button)<StyledButtonProps>`
  && {
    &:hover,
    &:active,
    &:focus {
      color: ${({ $isSuccess }) =>
        $isSuccess
          ? colors.BACKGROUND.LIKE_GREEN
          : colors.BACKGROUND.DISLIKE_RED};
      border-color: ${({ $isSuccess }) =>
        $isSuccess
          ? colors.BACKGROUND.LIKE_GREEN
          : colors.BACKGROUND.DISLIKE_RED};
    }

    padding: 0.5rem;
    white-space: pre-line;
    height: auto;

    @media (max-width: ${BREAKPOINTS.MD}) {
      font-size: 0.875rem;
    }
  }
`

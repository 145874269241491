import React from 'react'
import { Form, FormInstance, Input, Space } from 'antd'
import { InputHeader, SubmitButton } from '@/utils/styles'
import { IChangeOrderAddressRequest } from '@/types/hooks/order'
import { ORDER_INSTRUCTION_TYPE } from '@/utils/constants/enums'
import { mixpanelTrackSubmit } from '@/components/Mixpanel/helpers'
import { removeEmojis } from '@/utils/functions/removeEmoji'

type AddressProps = {
  form: FormInstance
  token: string
  isSubmitting: boolean
  changeOrderAddress: (data: IChangeOrderAddressRequest, token: string) => void
}

const Address = ({
  form,
  token,
  isSubmitting,
  changeOrderAddress,
}: AddressProps) => {
  const handleAppendInstruction = async () => {
    try {
      await form.validateFields()
      const values = form.getFieldsValue()

      const dataToPost = {
        address1: removeEmojis(values.address1),
        address2: removeEmojis(values.address2),
        postcode: values.postcode,
      }

      mixpanelTrackSubmit(ORDER_INSTRUCTION_TYPE.ADDRESS)
      changeOrderAddress(dataToPost, token)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Form name='leaveWithNeighbour' form={form} layout='vertical'>
      <Space direction='vertical' size='small' style={{ width: '100%' }}>
        <div style={{ marginBottom: '0.5rem' }}>
          Please be advised that changes to the delivery address will result in
          an additional 1-3 working days to the delivery date.
        </div>
        <InputHeader>Postal Code</InputHeader>
        <Form.Item
          name='postcode'
          rules={[{ required: true, message: 'Please enter a postal code' }]}
        >
          <Input pattern='\d*' maxLength={6} />
        </Form.Item>
        <InputHeader>Street name, building</InputHeader>
        <Form.Item
          name='address1'
          rules={[{ required: true, message: 'Please enter your address' }]}
        >
          <Input />
        </Form.Item>
        <InputHeader>Unit no.</InputHeader>
        <Form.Item
          name='address2'
          rules={[{ required: true, message: 'Please enter your unit number' }]}
        >
          <Input />
        </Form.Item>
      </Space>
      <SubmitButton
        key='submit'
        type='primary'
        loading={isSubmitting}
        onClick={handleAppendInstruction}
        block
      >
        Submit
      </SubmitButton>
    </Form>
  )
}

export default Address

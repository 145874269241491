import styled from 'styled-components'
import { Form } from 'antd'

export const RadioTitle = styled.div`
  font-weight: bold;
  padding-top: 1.375rem;
`

export const StyledFormItem = styled(Form.Item)`
  && {
    &.ant-form-item {
      margin-bottom: 0.5rem;
    }

    .ant-form-item-explain-error {
      margin-top: 0.75rem;
    }
  }
`

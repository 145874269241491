import { DELIVERY_INSTRUCTIONS, ORDER_INSTRUCTION_TYPE } from './enums'

export const DELIVERY_OPTIONS = [
  {
    option: DELIVERY_INSTRUCTIONS.SAFE_PLACE,
    image: '/icon-safe-place.svg',
  },
  {
    option: DELIVERY_INSTRUCTIONS.NEIGHBOUR,
    image: '/icon-neighbour.svg',
  },
  {
    option: DELIVERY_INSTRUCTIONS.ADDRESS,
    image: '/icon-address.svg',
  },
  {
    option: DELIVERY_INSTRUCTIONS.PHONE,
    image: '/icon-phone.svg',
  },
  {
    option: DELIVERY_INSTRUCTIONS.DOORBELL,
    image: '/icon-doorbell.svg',
  },
]

export const DELIVERY_TYPE = {
  [DELIVERY_INSTRUCTIONS.SAFE_PLACE]: ORDER_INSTRUCTION_TYPE.SAFE_PLACE,
  [DELIVERY_INSTRUCTIONS.NEIGHBOUR]: ORDER_INSTRUCTION_TYPE.NEIGHBOUR,
  [DELIVERY_INSTRUCTIONS.ADDRESS]: ORDER_INSTRUCTION_TYPE.ADDRESS,
  [DELIVERY_INSTRUCTIONS.PHONE]: ORDER_INSTRUCTION_TYPE.PHONE,
  [DELIVERY_INSTRUCTIONS.DOORBELL]: ORDER_INSTRUCTION_TYPE.DOORBELL,
}

import styled from 'styled-components'
import { BREAKPOINTS } from '@/utils/constants/enums'
import { Layout } from 'antd'
import colors from '@/utils/constants/colors'

export const StyledLayout = styled(Layout)`
  && {
    height: 100vh;
    background-color: ${colors.BACKGROUND.WHITE};
  }
`

export const StyledHeader = styled(Layout.Header)`
  && {
    background-color: ${colors.BACKGROUND.BLACK};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 170px;
    }
  }
`

export const StyledContent = styled(Layout.Content)`
  && {
    max-width: 796px;
    margin: auto;
  }
`

export const DeliveryContainer = styled.div`
  padding: 2rem 1rem;
  background: url('/placeholder-art.svg'), ${colors.BACKGROUND.LIGHT_GREY};
  object-fit: cover;
  background-repeat: no-repeat;

  @media (max-width: ${BREAKPOINTS.MD}) {
    background: url('/placeholder-art-mobile.svg'),
      ${colors.BACKGROUND.LIGHT_GREY};
    background-repeat: repeat-x;
  }
`

export const DeliveryTitle = styled.div`
  font-size: 2rem;
  margin-bottom: 2rem;

  @media (max-width: ${BREAKPOINTS.MD}) {
    font-size: 1.5rem;
  }
`

export const SubTitle = styled.div`
  color: ${colors.TEXT.GREY};
`

export const TextEmphasis = styled.span`
  color: ${colors.TEXT.RED};
  font-weight: bold;
`

export const DeliveryData = styled.div`
  font-size: 1rem;
`

export const DeliveryOptionsContainer = styled.div`
  padding: 2rem 1rem 0 1rem;
`

export const DeliveryOptionsTitle = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
`

export const DeliverySection = styled.div`
  margin-top: 1.5rem;
`

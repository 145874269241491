import React, { useEffect } from 'react'
import { StyledModal } from '@/utils/styles'
import { DELIVERY_INSTRUCTIONS } from '@/utils/constants/enums'
import {
  SafePlace,
  Neighbour,
  Address,
  Phone,
  Doorbell,
} from '@/components/DeliveryInstructionForm'
import {
  IAppendOrderInstructionRequest,
  IChangeOrderAddressRequest,
} from '@/types/hooks/order'
import { IResponse } from '@/types/commons/api'
import { useRouter } from 'next/router'
import { Form } from 'antd'
import { IAddress } from '@/types/commons/constants'
import { mixpanelTrackFailedData } from '@/components/Mixpanel/helpers'

type DeliveryOptionsModalProps = {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  title: string
  isSubmitting: boolean
  responseStatus?: IResponse
  appendOrderInstruction: (
    data: IAppendOrderInstructionRequest,
    token: string
  ) => void
  changeOrderAddress: (data: IChangeOrderAddressRequest, token: string) => void
  setHasSubmittedInstruction: (hasSubmitted: boolean) => void
  setSuccessAddressInput: (address: IAddress) => void
}

const DeliveryOptionsModal = ({
  isVisible,
  setIsVisible,
  title,
  isSubmitting,
  responseStatus,
  appendOrderInstruction,
  changeOrderAddress,
  setHasSubmittedInstruction,
  setSuccessAddressInput,
}: DeliveryOptionsModalProps) => {
  const router = useRouter()
  const { t } = router.query

  const [addressForm] = Form.useForm()

  const getModalContent = () => {
    const formProps = {
      token: t as string,
      isSubmitting,
      appendOrderInstruction,
      changeOrderAddress,
    }

    switch (title) {
      case DELIVERY_INSTRUCTIONS.SAFE_PLACE:
        return <SafePlace {...formProps} />
      case DELIVERY_INSTRUCTIONS.NEIGHBOUR:
        return <Neighbour {...formProps} />
      case DELIVERY_INSTRUCTIONS.ADDRESS:
        return <Address form={addressForm} {...formProps} />
      case DELIVERY_INSTRUCTIONS.PHONE:
        return <Phone {...formProps} />
      case DELIVERY_INSTRUCTIONS.DOORBELL:
        return <Doorbell {...formProps} />
      default:
        return null
    }
  }

  useEffect(() => {
    if (responseStatus) {
      if (responseStatus?.data['status'] === 'SUCCESS') {
        setIsVisible(false)
        setHasSubmittedInstruction(true)
        if (title === DELIVERY_INSTRUCTIONS.ADDRESS) {
          setSuccessAddressInput(addressForm.getFieldsValue())
        }
      } else {
        mixpanelTrackFailedData(title)
        router.push('/error')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseStatus])

  return (
    <StyledModal
      title={title}
      visible={isVisible}
      width={600}
      centered
      onCancel={() => setIsVisible(false)}
      footer={null}
    >
      {getModalContent()}
    </StyledModal>
  )
}

export default DeliveryOptionsModal

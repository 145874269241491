import React, { useState, useEffect, useMemo } from 'react'
import Status from '@/components/Status'
import { STATUS_LABELS } from '@/utils/constants/status'
import { STATUSES } from '@/utils/constants/enums'
import { StyledButton } from './styles'
import { Row, Col, Input, Form } from 'antd'
import { LikeFilled, DislikeFilled } from '@ant-design/icons'
import useCsatRating from '@/hooks/api/useCsatRating'
import colors from '@/utils/constants/colors'
import { SubmitButton, StyledDrawer } from '@/utils/styles'
import { useRouter } from 'next/router'
import { DEFAULT_SOURCE } from '@/utils/constants/defaults'
import { ISubmitCsatRatingRequest } from '@/types/hooks/order'
import { IAddress } from '@/types/commons/constants'
import { isEmpty } from 'lodash'

const { TextArea } = Input

type SuccessProps = {
  trackingId: string
  deliveryType: string
  successAddress?: IAddress
}

const Success = ({
  trackingId,
  deliveryType,
  successAddress,
}: SuccessProps) => {
  const router = useRouter()
  const { t } = router.query
  const [form] = Form.useForm()

  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)
  const [hasThumbsDowned, setHasThumbsDowned] = useState<boolean>(false)
  const [drawerHeight, setDrawerHeight] = useState<number>(130)
  const [drawerTitle, setDrawerTitle] = useState<string>(
    'What did you think about this feature?'
  )
  const { submitCsatRating } = useCsatRating()

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsDrawerVisible(true)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const submitRating = (rating: number, feedback?: string) => {
    if (t) {
      let dataToPost: ISubmitCsatRatingRequest = {
        trackingId,
        type: deliveryType,
        rating,
        source: DEFAULT_SOURCE,
      }

      if (feedback) {
        dataToPost = {
          ...dataToPost,
          feedback,
        }
      }

      submitCsatRating(dataToPost, t as string)

      if (feedback || rating === 1) {
        setIsDrawerVisible(false)
      }
    }
  }

  const handleThumbsDown = () => {
    submitRating(-1)
    setHasThumbsDowned(true)
    setDrawerHeight(220)
    setDrawerTitle(
      'Is there anything we can do to make our product fit your needs even better?'
    )
  }

  const getRatingButtons = useMemo(
    () => (
      <Row gutter={16}>
        <Col span={12}>
          <StyledButton
            size='large'
            $isSuccess
            block
            icon={
              <LikeFilled style={{ color: colors.BACKGROUND.LIKE_GREEN }} />
            }
            onClick={() => submitRating(1)}
          >
            Great!
          </StyledButton>
        </Col>
        <Col span={12}>
          <StyledButton
            size='large'
            $isSuccess={false}
            block
            icon={
              <DislikeFilled style={{ color: colors.BACKGROUND.DISLIKE_RED }} />
            }
            onClick={() => handleThumbsDown()}
          >
            Not what I need
          </StyledButton>
        </Col>
      </Row>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const getFeedback = useMemo(
    () => (
      <Form form={form}>
        <Form.Item name='feedback'>
          <TextArea rows={2} />
        </Form.Item>
        <SubmitButton
          key='submit'
          type='primary'
          block
          style={{ margin: '0' }}
          onClick={() => submitRating(-1, form.getFieldValue('feedback'))}
        >
          Submit
        </SubmitButton>
      </Form>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <>
      <Status
        type={
          !isEmpty(successAddress)
            ? STATUS_LABELS[STATUSES.SUCCESS_ADDRESS]
            : STATUS_LABELS[STATUSES.SUCCESS]
        }
        successAddress={successAddress}
      />
      <StyledDrawer
        placement='bottom'
        visible={isDrawerVisible}
        title={drawerTitle}
        maskStyle={{ background: 'none' }}
        height={drawerHeight}
        closable={false}
        onClose={() => setIsDrawerVisible(false)}
      >
        {!hasThumbsDowned ? getRatingButtons : getFeedback}
      </StyledDrawer>
    </>
  )
}
export default Success

import { useState } from 'react'
import consigneeApi from '@/services/api/consigneeApi'
import handleResponse from '@/utils/handleResponse'
import { ApiResponse } from 'apisauce'
import { ISubmitCsatRatingRequest } from '@/types/hooks/order'

const useCsatRating = () => {
  const [isSubmittingCsat, setIsSubmittingCsat] = useState<boolean>(false)

  const submitCsatRating = async (
    data: ISubmitCsatRatingRequest,
    token: string
  ): Promise<void> => {
    setIsSubmittingCsat(true)
    const res: ApiResponse<any> = await consigneeApi.submitCsatRating(
      data,
      token
    )
    handleResponse(
      res,
      () => {
        setIsSubmittingCsat(false)
      },
      () => {
        setIsSubmittingCsat(false)
      }
    )
  }

  const submitCsatRatingV2 = async (
    data: ISubmitCsatRatingRequest
  ): Promise<void> => {
    setIsSubmittingCsat(true)
    const res: ApiResponse<any> = await consigneeApi.submitCsatRatingV2(data)
    handleResponse(
      res,
      () => {
        setIsSubmittingCsat(false)
      },
      () => {
        setIsSubmittingCsat(false)
      }
    )
  }

  return {
    submitCsatRating,
    submitCsatRatingV2,
    isSubmittingCsat, //TODO: test where this is used
  }
}

export default useCsatRating
